.feedback {
    background-image: url('./static/background-form.webp');
    background-position: center;
    background-size: cover;
    padding-top: 90px;
    padding-bottom: 90px;
}

.feedback__text span {
    color: #FFF;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    line-height: 40px;
}

.feedback__form input {
    padding: 10px 60px;
    font-size: 22px;
    background-color: transparent;
    border: 2px solid #FFF;
    color: #FFF;
}

.feedback__form input:focus {
    background-color: #FFF;
    color: #000;
    border: 2px solid #FFF;
}

.feedback__form input:hover {
    border: 2px solid #FFF;
}

.feedback__form input::-webkit-input-placeholder { color: #FFF; opacity: 0.7; }
.feedback__form input::-moz-placeholder { color: #FFF; opacity: 0.7; }
.feedback__form input:-moz-placeholder { color: #FFF; opacity: 0.7; }
.feedback__form input:-ms-input-placeholder { color: #FFF; opacity: 0.7; }

.submit-button {
    border: 0px !important;
    width: 100%;
    height: 58px;
    font-size: 22px;
    color: #000;
    padding: 8px 15px;
    text-transform: capitalize;
    font-weight: 500;
    background: #fff;
    opacity: 0.77;
}

.submit-button:focus {
    outline: none;
}

.submit-button:hover
{
    background-color: #007270!important;
}

