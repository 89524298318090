.certificates-header {
    height: 480px;
    background-image: url('../static/CERTIFICATION-PAGE-BANNER.png');
    background-position: center;
    background-size: cover;
    align-items: center;
}

.sertificates {
    padding-top: 64px;
    padding-bottom: 82px;
}

.sertificate__card {
    border: none;
}

.sertificate__card img {
    box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.25);
    position: relative;
}

.sertificate__card::after {
    content: "";
    width: 110px;
    height: 90px;
    position: absolute;
    background-size: 110px 90px;
    top: -30px;
    right: -10px;
}

.certif__01::after {
    background-image: url('../static/sert1_icon.webp');
}

.certif__02::after {
    background-image: url('../static/sert2_icon.webp');
}

.certif__03::after {
    background-image: url('../static/sert3_icon.webp');
}

.certif__04::after {
    background-image: url('../static/sert4_icon.webp');
}

.certif__05::after {
    background-image: url('../static/sert5_icon.webp');
}