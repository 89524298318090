.loader {
    transition: 1s ease;
    width: 150px;
    height: 150px;
    filter: url(#gooey);
    animation: animate 16s linear infinite;
    position: relative;
    left: 0;
    right: 0;
    top: 35%;
    margin-left: auto;
    margin-right: auto;
    z-index: 999;
    margin-bottom: 60px;
    margin-top: 60px;
}

.loader span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    transform: rotate(calc(45deg * var(--i)));
}

.loader span::before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - 20px);
    width: 20px;
    height: 20px;
    background: linear-gradient(45deg, #077e80, #044549);
    border-radius: 50%;
    box-shadow: 0 0 30px #16b7c7;
}

.rotate {
    animation: animate 1.5s ease-in-out infinite;
    animation-delay: calc(0.1s * var(--r));
}

svg {
    width: 0;
    height: 0;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}