.main-carousel .carousel-item {
    background: url(./static/hmpg_bnr-1.png) no-repeat;
    height: 700px;
    width: 100%;
    background-position: center bottom;
    background-size: cover;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.main-carousel .carousel-item .content {
    padding-top: 7%;
    background: rgb(0 0 0 / 15%);
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
}

img.baner_Arm_1d8i3 {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 0px;
}