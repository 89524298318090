
.site-layout-content-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
:where(.css-dev-only-do-not-override-1e3x2xa).ant-card-bordered .ant-card-cover {
    height: 220px;
    align-items: center;
    text-align: center;
    margin-top: -1px;
    margin-inline-start: -1px;
    margin-inline-end: -1px;
    
}
:where(.css-dev-only-do-not-override-1e3x2xa).ant-card .ant-card-meta-description {
    color: black;
    font-weight: bold;
}
.ant-card .ant-card-body {
    padding: 0;
    border-radius: 0 0 8px 8px;
}
