.contacts__header {
    height: 120px;
    background: linear-gradient(93deg, #007270 26.09%, #25ABA6 48.88%, #067170 76.71%);
    display: flex;
    justify-content: center;
}

.contacts__header h1 {
    color: #FFF;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
}

.breadCrumb {
    padding-top: 30px;
    padding-bottom: 30px;
}