.content {
    width: 100%;
    display: flex;
    padding-left: calc((100vw - 1158px) / 2)!important;
    padding-right: calc((100vw - 1158px) / 2)!important;
    background-color: #FFF;
    margin-left: 0;
    margin-right: 0;
}

.main * {
    font-family: Roboto;
}

.buttons-connection {
    padding: 55px 0;
    justify-content: center;
    gap: 70px;
}

.buttons-connection > div {
    flex: 0;
}

.buttons-connection button {
    background-color: #FD9E01;
    border-radius: 20px;
    display: flex;
    align-items: center;
    height: 40px;
    box-shadow: 0 3px 6px #7f7f7f;
    border: none;
}

.buttons-connection button:hover span {
    color: #FFF;
}

.buttons-connection a {
    line-height: 0;
    font-size: 20px;
    font-weight: normal;
}

.buttons-connection a:hover {
    text-decoration: none;
    color: #FFF;
}

.main-menu {
    justify-content: center;
    gap: 5px;
}

.main__item {
    position: relative;
}

.main__item>img {
    width: 100%;
}

.main__item>div {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background: rgba(0, 95, 104, 0.55);
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.main__item p {
    color: #FFF;
    z-index: 9999;
    font-size: 24px;
    font-weight: bold;
}

.main__h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 40px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 36px;
    font-weight: 400;
    background-color: #FFF;
}

.main__info-lubricants {
    flex-direction: column;
    padding-bottom: 100px;
}

.main__info-lubricants p {
    font-size: 16px;
    font-weight: 300;
}

.popular__goods .ant-image .ant-image-img {
    object-fit: contain;
    height: 100% !;
}

.popular__goods .ant-image,
.popular__goods .ant-card-bordered .ant-card-cover {
    height: 260px !important;
}

.popular__goods .ant-card-bordered {
    padding: 30px 50px 15px;
    border-radius: 5px;
    background: #F4F4F4;
    box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.25);
}

.popular__goods .ant-card .ant-card-body {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.popular__goods .ant-card .ant-card-body p:nth-child(1) {
    font-weight: 700;
    margin-top: 20px;
}

.ant-card-body button a {
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-variant: small-caps;
}

.popular__goods_button {
    border-radius: 5px;
    background: linear-gradient(180deg, #0F97A4 0%, #007270 100%);
    box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
}

.button-all-goods {
    padding-top: 30px;
    justify-content: center!important;
}

.button-all-goods button {
    border-radius: 5px;
    border: 2px solid #007270;
    background: linear-gradient(180deg, rgba(15, 151, 164, 0.00) 0%, rgba(0, 114, 112, 0.00) 100%);
    box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.25);
    padding: 8px 20px;
    height: auto;
}

.button-all-goods button a {
    color: #007270;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-variant: small-caps;
}

.main__avantages {
    padding-top: 90px;
    padding-bottom: 100px;
}

.main__avantages .ant-image {
    display: flex;
    justify-content: center;
}

.main__avantages .ant-image img {
    width: auto;
}

.main__avantages .ant-card {
    padding: 35px 20px 20px;
    background: #F4F4F4;
    box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.25);
    height: 100%;
}

.main__avantages .title__avantages {
    padding: 35px 0;
    color: #000;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
}

.main__avantages p:not(.title__avantages):before {
    content: url(static/mark.svg);
    position: relative;
    top: 5px;
    margin-right: 5px;
}

.horizontal-gallery {
    width: 100%;
    background-color: #FFF;
    padding-bottom: 100px;
    padding-left: 100px;
    padding-right: 100px;
    scroll-snap-stop: always;
}

.horizontal-gallery .slick-slide {
    width: auto !important;
}

.horizontal-gallery .slick-track {
    transform: translate3d(0px, 0px, 0px);
}