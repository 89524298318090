main h1 {
    margin-bottom: 0!important;
}

.product__photo .carousel-item {
    height: 660px;
    width: 660px;
    background-position: center bottom;
    background-size: cover;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #F4F4F4;
}

.product__photo  .carousel-item .content {
    padding-top: 7%;
    background: rgb(0 0 0 / 15%);
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
}

.product__photo img {
    height: 495px!important;
    width: auto!important;
}

.product__header {
    height: 120px;
    background: linear-gradient(93deg, #007270 26.09%, #25ABA6 48.88%, #067170 76.71%);
    display: flex;
    justify-content: center;
}

.product__header h1 {
    color: #FFF;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
}

.product__variants {
     display: flex;
     gap: 5px;
     margin-top: 5px;
}

.product__variants > div > div {
    background-color: #F4F4F4;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product__variants .ant-card-cover,
.product__variants .ant-card-cover .ant-image {
    height: 120px;
}

.product__variants .ant-card-cover img {
    object-fit: contain;
    height: 100%;
    padding: 12px;
}

.product__variants .ant-card-body {
    padding: 0;
}

.product__info * {
    font-family: Roboto;
}

.product__info .brief__info {
    border: none;
}

.product__info .brief__info p:nth-child(1) {
    color: #0A6E78;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.product__info .brief__info p:nth-child(2) {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.product__info .brief__info p:nth-child(3) {
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.product__info .brief__info button {
    width: 100%;
    height: 58px;
    border-radius: 5px;
    background: linear-gradient(180deg, #0F97A4 0%, #007270 100%);
}

.product__info .brief__info button span {
    color: #FFF;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
}

.product__info .description {
    border: none;
}

.product__info .description button {
    width: 240px;
    height: 37px;
    margin: 50px auto 100px 60px;
    border-radius: 5px;
    background: linear-gradient(180deg, #0F97A4 0%, #007270 100%);
    box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.25);
}

.product__info button:disabled,
.product__other button:disabled {
    background: linear-gradient(180deg, #818181 100%, #b7b7b7 0%);
    opacity: 0.7;
}

.product__info .description button span,
.product__other button span {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-variant: small-caps;
}

.product__other {
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    padding-bottom: 100px;
    margin: 0;
    position: relative;
}

.product__other > div {
    width: 100%;
}

.product__other .ant-card {
    display: flex;
    flex-direction: column;
}

.product__other .ant-image .ant-image-img {
    object-fit: contain;
    height: 100%;
}

.product__other .ant-image,
.product__other .ant-card-bordered .ant-card-cover {
    height: 260px!important;
}

.product__other .ant-card-bordered {
    height: 100%;
    padding: 23px 0;
    border-radius: 5px;
    background: #F4F4F4;
    box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.25);
}

.product__other .ant-card .ant-card-body {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 0 0 8px 8px;
}

.product__other .ant-card-body > div {
    flex-grow: 1;
 }

 .product__other .ant-card-body > p {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
 }

.product__other .ant-card .ant-card-body p:nth-child(1) {
    color: #0A6E78;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
}

.product__other_button {
    border-radius: 5px;
    background: linear-gradient(180deg, #0F97A4 0%, #007270 100%);
    box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.25);
    width: 238px;
}