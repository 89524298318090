h1 {
    margin-bottom: 0;
}

.site-layout-content-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.categories__items .ant-card .ant-card-meta-description {
    color: black;
    font-weight: bold;
}

.categories__header {
    height: 120px;
    background: linear-gradient(93deg, #007270 26.09%, #25ABA6 48.88%, #067170 76.71%);
    display: flex;
    justify-content: center;
}

.categories__header h1 {
    color: #FFF;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
}

.categories__divider {
    margin-bottom: 0!important;
    margin-top: 0!important;
    color: #000!important;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 300!important;
    line-height: normal;
    opacity: 1;
    padding-bottom: 60px;
}
.categories__divider .ant-divider-inner-text:after {
    border-block-start-color: rgba(0, 0, 0, 0.8);
    color: rgba(0, 0, 0, 0.8);
    opacity: 1;
}

.breadCrumb {
    padding-top: 30px;
    padding-bottom: 30px;
}

.categories__section {
    flex-direction: column;
}

.categories__section .ant-row > div {
    width: 100%;
}

.categories__section h2 {
    color: #000;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}

.categories__items {
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    padding-bottom: 60px;
    margin: 0;
    position: relative;
}

.categories__items .product {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 23px 0;
    border-radius: 5px;
    background: #F4F4F4;
    box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.25);
}

.categories__items .ant-col .ant-card .ant-card-cover .ant-image {
    height: 220px!important;
}

.categories__items .product__img {
    object-fit: contain;
    height: 100%;
}

.categories__items .ant-col .ant-card .ant-card-body {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 0 0 8px 8px;
}

.categories__items .ant-card-body > div {
    flex-grow: 1;
 }

.categories__items .ant-card-body div p:nth-child(1) {
    color: #0A6E78;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
}

.categories__items_button {
    border-radius: 5px;
    background: linear-gradient(180deg, #0F97A4 0%, #007270 100%);
    box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.25);
    width: 238px;
}
.downloadPrice {
    padding-bottom: 25px;
}
.downloadPrice a{
    padding-right: 15px;
}