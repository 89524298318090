.logo {
    width: 300px;
}

.icon {
    margin-right: 20px;
}

footer {
    padding: 67px 0 28px 0!important;
}

footer * {
    font-family: Roboto!important;
}
